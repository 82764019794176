{/* <img :src="$img['footerLogo']" alt="logo"></img> */
}
import RailsConfig from '@plugins/rails_config'

export default {
    "footerLogo": require('./services/' + RailsConfig.global.project_name.toLowerCase() + '/footerLogo.png'),
    "logo": require('./services/' + RailsConfig.global.project_name.toLowerCase() + '/logo.png'),
    "logo_small": require('./services/' + RailsConfig.global.project_name.toLowerCase() + '/logo_small.png'),
    "favicon": require('./services/' + RailsConfig.global.project_name.toLowerCase() + '/favicon.png'),
    "favicon_morphx": require('./services/morphx/favicon.png'),
    "contactUs": require('./services/' + RailsConfig.global.project_name.toLowerCase() + '/contactUs.png'),
    "avatar": require('./newFemale.svg'),
    "instagramL": require('./instagramLogo.png'),
    "googleL": require('./googleLogo.png'),
    "facebookL": require('./facebookL.png'),
    "linkedinL": require('./linkedinL.png'),
    "male_default": require('./newMale.svg'),
    "hidden_default": require('./newHidden.svg'),
    "layout_grid": require('./grid.svg'),
    "layout_presenterFocus": require('./presenterFocus.svg'),
    "layout_presenterOnly": require('./presenterOnly.svg'),
    "unite_custom_pricing": require('./unite_custom_pricing.svg'),


    "bp_bubble": require('./BusinessPlans/bubble.png'),
    "bp_Characters": require('./BusinessPlans/Characters.png'),
    "bp_check": require('./BusinessPlans/check.png'),
    "bp_check2": require('./BusinessPlans/check2.png'),
    "bp_gr-r": require('./BusinessPlans/gr-r.png'),
    "bp_gr-rBG": require('./BusinessPlans/gr-rBG.png'),
    "bp_money": require('./BusinessPlans/money.png'),
    // "bp_pl_1": require('./BusinessPlans/pl_1.svg'),
    "bp_pl_1r": require('./BusinessPlans/pl_1r.png'),
    "bp_pl_2": require('./BusinessPlans/pl_2.png'),
    "bp_pl_bottom": require('./BusinessPlans/pl_bottom.png'),
    "adds_1": require('./BusinessPlans/adds_1.png'),
    "adds_2": require('./BusinessPlans/adds_2.png'),
    "adds_3": require('./BusinessPlans/adds_3.png'),
    "not_logged_banner": require('./not_logged_banner.png'),
    "unite_banner": require('./unite_banner.png'),

    "mastercard": require('./mastercard.svg'),
    "paypal": require('./paypal.svg'),
    "visa": require('./visa.svg'),
    "speaker": require('./speaker.svg'),

    "bad-smile": require('./BadSmile.svg'),
    "normal-smile": require('./NormalSmile.svg'),
    "good-smile": require('./GoodSmile.svg'),

    /* Roles Page Tiles*/
    "RS__1": require('./roles/RS__1.svg'),
    "RS__2": require('./roles/RS__2.svg'),
    "RS__3": require('./roles/RS__3.svg'),
    "RS__4": require('./roles/RS__4.svg'),

    "HP__1": require('./homePage/1.jpg'),
    "HP__2": require('./homePage/2.jpg'),
    "HP__3": require('./homePage/3.jpg'),
    "HP__4": require('./homePage/4.jpg'),
    "HP__5": require('./homePage/5.jpg'),
    "HP__6": require('./homePage/6.jpg'),
    "HP__7": require('./homePage/7.jpg'),
    "HP__8": require('./homePage/8.jpg'),
    "HP__9": require('./homePage/9.jpg'),
    "HP__10": require('./homePage/10.jpg'),
    "HP__11": require('./homePage/11.jpg'),
    "HP__12": require('./homePage/12.jpg'),
    "HP__13": require('./homePage/13.jpg'),
    "HP__14": require('./homePage/14.jpg'),
    "HP__15": require('./homePage/15.jpg'),
    "HP__16": require('./homePage/16.jpg'),

    "US__1": require('./homePage/uniteBanner/1.jpg'),
    "US__2": require('./homePage/uniteBanner/2.jpg'),
    "US__3": require('./homePage/uniteBanner/3.jpg'),
    "US__4": require('./homePage/uniteBanner/4.jpg'),
    "US__5": require('./homePage/uniteBanner/5.jpg'),
    "US__6": require('./homePage/uniteBanner/6.jpg'),
    "US__7": require('./homePage/uniteBanner/7.jpg'),
    "US__8": require('./homePage/uniteBanner/8.jpg'),
    "US__9": require('./homePage/uniteBanner/9.jpg'),
    "US__10": require('./homePage/uniteBanner/10.jpg'),
    "US__11": require('./homePage/uniteBanner/11.jpg'),
    "US__12": require('./homePage/uniteBanner/12.jpg'),
    "US__13": require('./homePage/uniteBanner/13.jpg'),
    "US__14": require('./homePage/uniteBanner/14.jpg'),
    "US__15": require('./homePage/uniteBanner/15.jpg'),
    "US__16": require('./homePage/uniteBanner/16.jpg'),
}