<template>
    <svg width="42" height="149" viewBox="0 0 42 149" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.6988 148.102L34.4325 147.964L33.685 147.526C32.7753 146.876 31.9871 146.07 31.3567 145.146C30.3899 143.661 29.7541 141.985 29.4923 140.232C29.1418 137.91 29.2557 135.542 29.8274 133.264C30.6197 130.548 31.7239 127.933 33.118 125.472C34.7069 122.674 36.141 119.792 37.4138 116.837C38.677 113.517 39.3515 110.002 39.407 106.45C39.4111 102.635 38.9436 98.8349 38.0152 95.1349C36.168 87.4884 33.0836 80.6152 30.5663 74.2832C28.2157 68.698 26.4591 62.881 25.3254 56.9283C24.9553 54.6266 24.76 52.3003 24.7412 49.9691C24.7412 48.9467 24.8099 47.9845 24.8873 47.1081C24.9646 46.2318 25.0677 45.4328 25.2052 44.7197L25.5918 42.8381C25.7292 42.2968 25.8839 41.8501 25.987 41.4892C26.0901 41.1284 26.176 40.8706 26.2447 40.6301C26.2691 40.5322 26.3037 40.4372 26.3478 40.3466C26.3367 40.4456 26.3166 40.5433 26.2877 40.6387C26.2361 40.8535 26.1588 41.1284 26.0729 41.4978C25.987 41.8673 25.8495 42.314 25.7206 42.8553C25.5918 43.3966 25.5059 44.0323 25.377 44.7368C25.2481 45.4413 25.2051 46.2404 25.102 47.1081C24.9989 47.9759 25.0161 48.9295 24.9989 49.9519C25.0335 52.256 25.246 54.5539 25.6347 56.8252C26.8101 62.746 28.5922 68.53 30.9529 74.0856C33.4874 80.4004 36.589 87.2909 38.4533 94.9889C39.392 98.7249 39.8595 102.564 39.8452 106.416C39.7814 110.016 39.0894 113.578 37.8004 116.94C36.5048 119.908 35.0419 122.799 33.4187 125.601C32.0224 128.043 30.9097 130.638 30.1023 133.333C29.5251 135.577 29.3937 137.912 29.7157 140.206C29.9542 141.933 30.5543 143.59 31.477 145.069C32.0843 145.989 32.8424 146.799 33.7194 147.466L34.4325 147.939C34.5267 147.984 34.616 148.038 34.6988 148.102Z" fill="#263238"/>
        <path d="M24.0715 56.7908C16.5195 53.3542 9.88685 45.9654 5.27318 39.0836C0.65951 32.2018 -1.35951 23.3524 0.977398 15.4053C3.3143 7.45806 10.4281 0.799592 18.6588 0.0693096C27.4909 -0.721114 36.0567 5.34453 39.7941 13.3862C43.5314 21.4279 41.5124 32.4767 37.1479 40.1919C32.1905 48.9038 30.8503 51.7561 24.0629 56.7908" fill="var(--announcement__sh)"/>
        <path d="M27.3362 58.1139C26.1672 57.1763 25.2761 55.9376 24.7587 54.5312L24.037 55.3904C23.1778 56.2925 22.6022 58.681 22.4476 59.9095C22.4046 60.2618 22.4475 60.7086 22.7654 60.8632C23.0833 61.0179 23.4957 60.7172 23.6762 60.3821C23.8566 60.047 23.9769 59.6518 24.2862 59.437C24.2739 59.8335 24.3532 60.2275 24.5181 60.5883C24.6026 60.768 24.7425 60.916 24.9172 61.0103C25.092 61.1046 25.2924 61.1404 25.489 61.1124C25.6793 61.0431 25.8415 60.9129 25.9503 60.742C26.059 60.5711 26.1083 60.3691 26.0904 60.1673C26.0524 59.7642 25.9199 59.3756 25.7038 59.0332C26.0113 59.4169 26.4143 59.7132 26.8722 59.8924C27.106 59.9766 27.3634 59.9663 27.5896 59.8636C27.8159 59.7609 27.9931 59.5739 28.0836 59.3425C28.1867 58.8872 27.7314 58.4146 27.3362 58.1139Z" fill="var(--announcement__sh)"/>
        <path d="M25.5919 55.6137C25.695 55.6824 25.3427 56.2838 24.5609 56.4728C23.7791 56.6619 23.1691 56.3526 23.2292 56.2495C23.2893 56.1464 23.822 56.2065 24.4406 56.0261C25.0592 55.8457 25.4888 55.5278 25.5919 55.6137Z" fill="#263238"/>
        <g opacity="0.3">
            <path d="M11.9999 5.39613C3.88084 10.6971 1.54395 19.5464 4.3362 28.8339C5.19536 31.7894 6.81057 34.6762 9.70593 36.1024C10.4674 36.4825 11.2968 36.7073 12.1459 36.7639C16.4417 37.0302 18.5381 33.3273 18.8903 29.779C19.1481 27.2015 18.6497 24.5639 18.581 21.9521C18.5123 19.3402 18.959 16.5308 20.7461 14.6149C22.5331 12.6989 25.5058 11.7882 25.6605 8.82416C25.6793 8.11866 25.5338 7.41844 25.2356 6.77878C24.9374 6.13913 24.4946 5.57755 23.9422 5.13837C23.0923 4.43875 22.0974 3.93692 21.0296 3.66922C18.3075 2.99835 15.431 3.41766 13.0137 4.83769L11.9999 5.39613Z" fill="white"/>
        </g>
        <path d="M38.3592 22.244C38.402 22.661 38.402 23.0814 38.3592 23.4984C38.3592 24.2974 38.2475 25.4659 38.0671 26.8921C37.8434 28.5663 37.5279 30.227 37.122 31.8666C36.6117 33.8742 35.9775 35.8483 35.2232 37.7776C34.4438 39.6958 33.5516 41.5663 32.5513 43.3793C31.7205 44.8605 30.8084 46.2946 29.8192 47.675C29.1585 48.6018 28.4502 49.4937 27.697 50.347C27.4375 50.6748 27.15 50.9795 26.8379 51.2577C27.0411 50.892 27.2831 50.5493 27.5596 50.2353C28.0579 49.6082 28.7452 48.6717 29.5528 47.486C30.3604 46.3004 31.2711 44.857 32.1732 43.1902C34.1511 39.5881 35.6776 35.7561 36.7182 31.7807C37.1907 29.9507 37.5344 28.2753 37.7492 26.8663C37.9639 25.4573 38.1186 24.2888 38.1873 23.4984C38.2112 23.0764 38.2687 22.6569 38.3592 22.244Z" fill="white"/>
        <path d="M36.4773 12.9738C36.9363 13.773 37.2559 14.6447 37.4224 15.5512C37.7388 16.4075 37.9101 17.3106 37.9293 18.2232C37.1584 16.5778 36.6677 14.8152 36.4773 13.0081V12.9738Z" fill="white"/>
    </svg>
</template>

<script>
    export default {
        name: "Sh__1__svg"
    }
</script>