import { render, staticRenderFns } from "./BannerJoybee.vue?vue&type=template&id=028b1486&scoped=true&"
var script = {}
import style0 from "./BannerJoybee.vue?vue&type=style&index=0&id=028b1486&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028b1486",
  null
  
)

export default component.exports